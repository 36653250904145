import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

const MarkdownImg = ( { img } ) => {
	return <img src={img.src || 'https://images.unsplash.com/photo-1551887373-3c5bd224f6e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80'} alt={img.alt} title={img.title} />;
};

const MarkdownRenderer = ( { copy } ) => {
	const filteredCopy = () => {
		if ( copy.includes( '](//' ) ) {
			return copy.replace( /((]\(\/\/))/g, '](/' );
		}

		return copy;
	};

	return (
		<Markdown className="richText">{filteredCopy()}</Markdown>
	);
};

MarkdownImg.propTypes = { img: PropTypes.object };
MarkdownRenderer.propTypes = { copy: PropTypes.string };

export default MarkdownRenderer;
